import React, { useContext, useEffect, useState } from "react";
import { Skeleton, Card } from "@nextui-org/react";
import SubscriptionCards from "../Information/SubscriptionCards";
import CreateDashboardPage from "../UserInput/CreateDashboard";
import { DateFunctions } from "../../utils/DateFunctions";
import { AuthContext } from "../../App";
import CompanySelection from "../../components/dashboard/CompanySelection";
import PromptsAI from "../../components/dashboard/PromptsAI";
import MainCardSection from "../../components/dashboard/MainCardSection";

const DashboardPage = (props) => {
  const [companyContent, setCompanyContent] = useState({});
  const [selectedCompanyDetails, setSelectedCompanyDetails] = useState({});
  const [selectedCompany, setSelectedCompany] = useState({
    companyName: "",
    companyID: "",
  });

  const [currentCardIndex, setCurrentCardIndex] = useState(0); // Keep track of the current card index

  const { userInfo, services } = useContext(AuthContext);

  let DateClass = new DateFunctions();
  const { numberOfDaysInMonth, currentYear, currentMonth, currentMonthNumber } =
    DateClass;

  const cardKeys = Object.keys(companyContent);
  const currentCardKey = cardKeys[currentCardIndex];
  const currentCardData = companyContent[currentCardKey] || {};

  useEffect(() => {
    console.log("useEffect");

    setSelectedCompany({
      companyName: userInfo?.companies?.[0]?.companyName || "",
      companyID: userInfo?.companies?.[0]?.companyID || "",
    });
  }, [userInfo]);

  useEffect(() => {
    console.log("useEffect");
    let imageObject;
    const fetchData = async () => {
      try {
        if (!selectedCompany.companyID) {
          return;
        }

        const [imagesResponse, companyDetails, companyPosts] =
          await Promise.all([
            services.companyService.getAllImagesForCompany(
              selectedCompany.companyID,
              currentYear,
              currentMonthNumber
            ),
            services.companyService.getCompanyByID(selectedCompany.companyID),
            services.companyService.getCompanyPosts(
              selectedCompany.companyID,
              currentMonthNumber
            ),
          ]);

        // console.log(companyPosts);

        if (
          companyDetails.data.data.getCompanyByID.imagePrompt &&
          companyDetails.data.data.getCompanyByID.textPrompt
        ) {
          setSelectedCompanyDetails({
            imagePrompt: companyDetails.data.data.getCompanyByID.imagePrompt,
            textPrompt: companyDetails.data.data.getCompanyByID.textPrompt,
          });
        } else {
          setSelectedCompanyDetails({
            imagePrompt: "",
            textPrompt: "",
          });
        }

        imageObject = Array.from(
          { length: numberOfDaysInMonth },
          (_, i) => i + 1
        ).reduce(
          (obj, day) => ({
            ...obj,
            [`${currentYear}-${currentMonthNumber}-${day}`]: {
              imageUrl: null,
              year: currentYear,
              month: currentMonthNumber,
              day,
              monthName: currentMonth,
            },
          }),
          {}
        );

        if (
          imagesResponse.data.data.getAllImages !== null &&
          companyPosts.data.data.getCompanyPosts !== null
        ) {
          let imageArray = imagesResponse.data.data.getAllImages.imageUrls;

          for (const imageUrl of imageArray) {
            const url = new URL(imageUrl);
            const pathSegments = url.pathname.split("/");

            const year = pathSegments[3];
            const month = pathSegments[4];
            const day = pathSegments[5];

            imageObject[`${year}-${month}-${day}`] = {
              imageUrl,
              year,
              month,
              day,
              monthName: currentMonth,
            };
          }
          // console.log(companyPosts.data.data.getCompanyPosts);
          for (const post of companyPosts.data.data.getCompanyPosts) {
            const year = post.postYear;
            const month = post.postMonth;
            const day = post.postDay;

            // Construct the key
            const key = `${year}-${month}-${day}`;

            // Check if key exists in the imageObject
            if (imageObject[key]) {
              // Add the postContent to the object
              imageObject[key].postContent = post.postContent;
              imageObject[key].postID = post.postID;
            } else {
              // Handle the case where the key doesn't exist, if needed
              console.warn(`Key ${key} not found in imageObject`);
            }
          }
        }
        setCompanyContent(imageObject);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (selectedCompany.companyID) {
      fetchData();
    }
  }, [
    currentMonth,
    currentMonthNumber,
    currentYear,
    numberOfDaysInMonth,
    userInfo.idToken,
    selectedCompany,
    selectedCompany.companyID,
    services.companyService,
  ]);

  const handleCompanyChange = (event) => {
    const selected = userInfo?.companies.find(
      (company) => company.companyID === event.target.value
    );
    // setTimeout(() => {
    setSelectedCompany({
      companyName: selected?.companyName,
      companyID: selected?.companyID,
    });

    // }, 200000); // 2000ms (2 seconds) delay
  };

  const handleImagePromptChange = (event) => {
    setSelectedCompanyDetails((prevDetails) => ({
      ...prevDetails,
      imagePrompt: event.target.value,
    }));
  };

  const handleTextPromptChange = (event) => {
    setSelectedCompanyDetails((prevDetails) => ({
      ...prevDetails,
      textPrompt: event.target.value,
    }));
  };

  const handlePromptSubmit = () => {
    services.companyService.updateCompanyDetails(
      selectedCompany.companyID,
      selectedCompany.companyName,
      null,
      null,
      null,
      selectedCompanyDetails.textPrompt,
      selectedCompanyDetails.imagePrompt
    );
  };

  return (
    <div>
      {userInfo ? (
        <>
          {!userInfo.isSubscriptionExpired ? (
            selectedCompany.companyName &&
            selectedCompany.companyID &&
            selectedCompanyDetails.imagePrompt &&
            selectedCompanyDetails.textPrompt ? (
              <>
                <div>
                  <CompanySelection
                    companies={userInfo.companies}
                    selectedCompany={selectedCompany}
                    handleCompanyChange={handleCompanyChange}
                  />

                  <PromptsAI
                    selectedCompanyDetails={selectedCompanyDetails}
                    handleImagePromptChange={handleImagePromptChange}
                    handleTextPromptChange={handleTextPromptChange}
                    handlePromptSubmit={handlePromptSubmit}
                  />
                </div>

                <div className="flex justify-center items-center h-full flex-col w-11/12 mx-auto">
                  <MainCardSection
                    companyContent={companyContent}
                    selectedCompany={selectedCompany}
                    selectedCompanyDetails={selectedCompanyDetails}
                    currentCardKey={currentCardKey}
                    currentCardData={currentCardData}
                    cardKeys={cardKeys}
                    currentCardIndex={currentCardIndex}
                    setCurrentCardIndex={setCurrentCardIndex}
                  />
                </div>
              </>
            ) : selectedCompany.companyName === "" &&
              selectedCompany.companyID === "" ? (
              <CreateDashboardPage />
            ) : (
              <div className="flex flex-row flex-wrap pt-10 pl-16 justify-center items-center">
                {Array.from({ length: 30 }, (_, i) => (
                  <Card
                    key={i}
                    className="flex w-1/6 space-y-5 p-4 m-2"
                    radius="2xl"
                  >
                    <Skeleton className="rounded-lg">
                      <div className="h-24 rounded-lg bg-default-300"></div>
                    </Skeleton>
                    <div className="space-y-3">
                      <Skeleton className="w-3/5 rounded-lg">
                        <div className="h-3 w-3/5 rounded-lg bg-default-200"></div>
                      </Skeleton>
                      <Skeleton className="w-4/5 rounded-lg">
                        <div className="h-3 w-4/5 rounded-lg bg-default-200"></div>
                      </Skeleton>
                      <Skeleton className="w-2/5 rounded-lg">
                        <div className="h-3 w-2/5 rounded-lg bg-default-300"></div>
                      </Skeleton>
                    </div>
                  </Card>
                ))}
              </div>
            )
          ) : (
            <div>
              <SubscriptionCards />
            </div>
          )}
        </>
      ) : (
        <div className="flex flex-row flex-wrap pt-10 pl-16 justify-center items-center">
          {Array.from({ length: 30 }, (_, i) => (
            <Card key={i} className="flex w-1/6 space-y-5 p-4 m-2" radius="2xl">
              <Skeleton className="rounded-lg">
                <div className="h-24 rounded-lg bg-default-300"></div>
              </Skeleton>
              <div className="space-y-3">
                <Skeleton className="w-3/5 rounded-lg">
                  <div className="h-3 w-3/5 rounded-lg bg-default-200"></div>
                </Skeleton>
                <Skeleton className="w-4/5 rounded-lg">
                  <div className="h-3 w-4/5 rounded-lg bg-default-200"></div>
                </Skeleton>
                <Skeleton className="w-2/5 rounded-lg">
                  <div className="h-3 w-2/5 rounded-lg bg-default-300"></div>
                </Skeleton>
              </div>
            </Card>
          ))}
        </div>
      )}
    </div>
  );
};

export default DashboardPage;
