import React, { useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Input,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
  useDisclosure,
} from "@nextui-org/react";

import { AuthContext } from "../../App";
import { gqlInputFromArrayOfObjects } from "../../utils/reusableFunctions";

const CreateDashboardPage = (props) => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const { userInfo, services } = useContext(AuthContext);

  const [formState, setFormState] = useState({
    companyName: "",
    address: "",
    description: "",
    industry: "",
    ownerID: "", // initial empty string
    companyID: uuidv4(),
    emailAddress: "", // initial empty string
  });

  useEffect(() => {
    if (userInfo) {
      setFormState((prevState) => ({
        ...prevState,
        ownerID: userInfo.cognitoUsername,
        emailAddress: userInfo.emailAddress,
      }));
    }
  }, [userInfo]);

  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = () => {
    console.log("submitted");
    // console.log(formState);

    if (userInfo.companies.companies === null) {
      userInfo.companies.companies = [];
    }

    const companiesGQLInput = gqlInputFromArrayOfObjects([
      {
        companyID: formState.companyID,
        companyName: formState.companyName,
      },
    ]);

    services.companyService.createDashboard(
      formState,
      userInfo,
      companiesGQLInput
    );

    // navigate("/dashboard");
  };

  return (
    <div className="flex flex-col items-center">
      <>
        <Button className="mt-5" onPress={onOpen}>
          Create Company
        </Button>
        <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
          <ModalContent>
            {(onClose) => (
              <>
                <ModalHeader className="flex flex-col gap-1 items-center">
                  Create a company
                </ModalHeader>
                <ModalBody>
                  <div className="flex flex-col w-full">
                    <Input
                      id="companyName"
                      label="Company Name"
                      variant="outlined"
                      className="mb-10"
                      value={formState.companyName}
                      onChange={handleChange}
                      required
                    />

                    <Input
                      id="address"
                      label="Address"
                      variant="outlined"
                      className="mb-10"
                      value={formState.address}
                      onChange={handleChange}
                    />

                    <Input
                      id="description"
                      label="Description"
                      variant="outlined"
                      className="mb-10"
                      value={formState.description}
                      onChange={handleChange}
                    />

                    <Input
                      id="industry"
                      label="Industry"
                      variant="outlined"
                      className="mb-10"
                      value={formState.industry}
                      onChange={handleChange}
                    />

                    <Button
                      variant="outlined"
                      className="mb-10"
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  </div>
                </ModalBody>
              </>
            )}
          </ModalContent>
        </Modal>
      </>
    </div>
  );
};

export default CreateDashboardPage;
