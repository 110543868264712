import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import { useCompanies } from "../../hooks/useCompanies";

const ListOfCompaniesPage = () => {
  const companies = useCompanies();

  return (
    <div className="flex flex-col m-4 mx-8">
      <div className="font-bold text-2xl mb-4">Companies</div>

      <div className="flex flex-row mb-4 items-center">
        {/*  future search bar*/}
        <div className="flex-grow"></div>
        <Button className="flex-none" color="primary" disableRipple>
          {" "}
          New{" "}
        </Button>
      </div>
      <Table color="primary" selectionMode="single" isHeaderSticky>
        <TableHeader>
          <TableColumn align="left">Company</TableColumn>
          <TableColumn>Owner</TableColumn>
        </TableHeader>
        <TableBody>
          {companies.map((company, index) => (
            <TableRow key={index}>
              <TableCell>
                <div className="flex flex-col items-start">
                  <div className="font-semibold text-primary text-lg">
                    {company.companyName}
                  </div>
                  <div className="text-foreground ml-2">
                    {company.companyID}
                  </div>
                  <div className="text-default-400 ml-2">
                    {company.description ?? ""}
                  </div>
                  <div className="text-default-400 ml-2">
                    {company.industry ?? ""}
                  </div>
                  <div className="text-default-400 ml-2">
                    {company.address ?? ""}
                  </div>
                </div>
              </TableCell>
              <TableCell>
                <div className="flex flex-col items-start">
                  <div className="font-semibold text-primary">
                    {company.ownerEmail}
                  </div>
                  <div className="text-foreground ml-2">{company.ownerID}</div>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default ListOfCompaniesPage;
