import React from "react";
import { Container, Typography } from "@mui/material";
const TermsAndConditions = () => {
  return (
    <Container maxWidth="sm" className="py-12 mb-4 text-justify">
      <Typography variant="h4" className="font-semibold mb-4" gutterBottom>
        Terms of Service for Media Pilot
      </Typography>
      <Typography variant="body1" className="mb-4">
        <strong>1. Acceptance of Terms</strong>
        <br />
        Welcome to Media Pilot ("we," "us," or "our"). By accessing or using our
        services, you agree to abide by these Terms of Service and all
        applicable laws and regulations. If you do not agree with these terms,
        please do not use our services.
      </Typography>
      <Typography variant="body1" className="mb-4">
        <strong>2. Description of Services</strong>
        <br />
        Media Pilot offers an AI-powered image and video generation service.
        Users can generate images and videos based on AI prompts. The content
        generated by the service is provided "as is," and we do not claim
        ownership over it.
      </Typography>
      <Typography variant="body1" className="mb-4">
        <strong>3. User Responsibilities</strong>
        <br />
        When using our services, you agree to:
        <ul>
          <li>
            Provide accurate and complete information during account
            registration.
          </li>
          <li>
            Use the service in compliance with all applicable laws and
            regulations.
          </li>
          <li>
            Refrain from using the service for any unlawful, harmful, or abusive
            purposes.
          </li>
          <li>Not impersonate others or provide false information.</li>
        </ul>
      </Typography>
      <Typography variant="body1" className="mb-4">
        <strong>4. Content Generated by AI</strong>
        <br />
        The content generated by our service is produced based on AI algorithms
        and prompts provided by users. We do not guarantee the accuracy,
        quality, or suitability of the generated content. You acknowledge that
        you are solely responsible for the content you generate using our
        service.
      </Typography>
      <Typography variant="body1" className="mb-4">
        <strong>5. User Content</strong>
        <br />
        You retain ownership of any content you upload or generate using our
        service. By using our service, you grant us a non-exclusive,
        royalty-free, worldwide license to use, reproduce, modify, and
        distribute the content for the purpose of providing and promoting our
        services.
      </Typography>
      <Typography variant="body1" className="mb-4">
        <strong>6. Privacy</strong>
        <br />
        We collect and use your personal information in accordance with our
        Privacy Policy. By using our services, you consent to the collection and
        use of your personal information as described in the Privacy Policy.
      </Typography>
      <Typography variant="body1" className="mb-4">
        <strong>7. Intellectual Property</strong>
        <br />
        Media Pilot and its related logos, trademarks, and content are protected
        by intellectual property laws. You may not use our intellectual property
        without our prior written consent.
      </Typography>
      <Typography variant="body1" className="mb-4">
        <strong>8. Limitation of Liability</strong>
        <br />
        We are not responsible for any direct, indirect, incidental,
        consequential, or punitive damages arising from the use of our services.
        We do not guarantee the availability, accuracy, or reliability of the
        service.
      </Typography>
      <Typography variant="body1" className="mb-4">
        <strong>9. Changes to Terms</strong>
        <br />
        We may update these Terms of Service periodically to reflect changes in
        our services and practices. We will notify you of significant changes
        through our service or via email.
      </Typography>
      <Typography variant="body1" className="mb-4">
        <strong>10. Termination</strong>
        <br />
        We reserve the right to suspend or terminate your access to our services
        at our discretion, without notice or liability.
      </Typography>
      <Typography variant="body1" className="mb-4">
        <strong>11. Contact Us</strong>
        <br />
        If you have any questions, concerns, or requests regarding these Terms
        of Service, please contact us at mediapilotofficial@gmail.com.
      </Typography>
      <Typography variant="body1" className="mb-4">
        Effective Date: August 9, 2023.
      </Typography>
    </Container>
  );
};
export default TermsAndConditions;
