import React, { useState } from "react";
import FormControl from "@mui/material/FormControl";
import { Tabs, Tab } from "@nextui-org/react";

import CardTile from "./CardTile";

const MainCardSection = (props) => {
  const {
    companyContent,
    selectedCompany,
    selectedCompanyDetails,
    currentCardKey,
    currentCardData,
    cardKeys,
    currentCardIndex,
    setCurrentCardIndex,
  } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
    console.log(isModalOpen);
  };

  const handlePreviousCard = () => {
    if (currentCardIndex > 0) {
      setCurrentCardIndex(currentCardIndex - 1);
    }
    console.log(currentCardIndex);
  };

  const handleNextCard = () => {
    if (currentCardIndex < Object.keys(companyContent).length - 1) {
      setCurrentCardIndex(currentCardIndex + 1);
    }
    console.log(currentCardIndex);
  };

  return (
    <Tabs aria-label="Options" className="mt-5">
      <Tab key="Month" title="Months">
        <FormControl sx={{ m: 1, color: "red" }}>
          {
            <div className="flex flex-row flex-wrap pt-10 pl-16 items-center">
              {Object.keys(companyContent).map((dateKey, index) => {
                const companyData = companyContent[dateKey];
                return (
                  <CardTile
                    key={index + 1}
                    currentDay={companyData.day}
                    currentMonth={companyData.monthName}
                    currentMonthNumber={companyData.month}
                    currentYear={companyData.year}
                    imageUrl={companyData.imageUrl}
                    companyID={selectedCompany.companyID}
                    companyImagePrompt={selectedCompanyDetails.imagePrompt}
                    companyTextPrompt={selectedCompanyDetails.textPrompt}
                    companyPosts={companyData.postContent}
                    companyPostID={companyData.postID}
                  />
                );
              })}
            </div>
          }
        </FormControl>
      </Tab>
      <Tab key="Day" title="Day">
        <FormControl>
          {
            <div
              className="flex justify-center items-center mt-10"
              style={{ width: "90vw" }}
            >
              <div className="w-full flex justify-center items-center relative">
                {/* Increase the dimensions and font size for the CardTile */}
                <CardTile
                  onClick={openModal}
                  shouldDoubleWidth={true}
                  key={currentCardKey}
                  currentDay={currentCardData.day}
                  currentMonth={currentCardData.monthName}
                  currentMonthNumber={currentCardData.month}
                  currentYear={currentCardData.year}
                  imageUrl={currentCardData.imageUrl}
                  companyID={selectedCompany.companyID}
                  companyImagePrompt={selectedCompanyDetails.imagePrompt}
                  companyTextPrompt={selectedCompanyDetails.textPrompt}
                  companyPosts={currentCardData.postContent}
                  companyPostID={currentCardData.postID}
                  style={{
                    width: "100%",
                    height: "500px",
                    fontSize: "18px",
                  }} // Adjust dimensions and font size
                />

                <button
                  className="absolute left-20 top-1/2 transform -translate-y-1/2 btn-prev-next"
                  onClick={handlePreviousCard}
                  disabled={currentCardIndex === 0}
                >
                  Previous
                </button>

                <button
                  className="absolute right-20 top-1/2 transform -translate-y-1/2 btn-prev-next"
                  onClick={handleNextCard}
                  disabled={currentCardIndex === cardKeys.length - 1}
                >
                  Next
                </button>
              </div>
            </div>
          }
        </FormControl>
      </Tab>
    </Tabs>
  );
};

export default MainCardSection;
