export const monthNamesLookup = {
  January: 0,
  February: 1,
  March: 2,
  April: 3,
  May: 4,
  June: 5,
  July: 6,
  August: 7,
  September: 8,
  October: 9,
  November: 10,
  December: 11,
};

export const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export function getDay(month, date, year) {
  const fullDate = new Date(year, monthNamesLookup[month], date);
  const dayOfWeekIndex = fullDate.getDay();

  return daysOfWeek[dayOfWeekIndex];
}
