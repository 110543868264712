import { Textarea, Button } from "@nextui-org/react";
import React, { useState } from "react";
import ClickAwayListener from "@mui/base/ClickAwayListener";

const PromptsAI = (props) => {
  const [isPromptEditing, setIsPromptEditing] = useState(false);

  const editPromptState = () => {
    setIsPromptEditing(true);
  };

  const promptStateClickAway = () => {
    setIsPromptEditing(false);
  };

  const {
    selectedCompanyDetails,
    handleImagePromptChange,
    handleTextPromptChange,
    handlePromptSubmit,
  } = props;
  return (
    <>
      {selectedCompanyDetails.imagePrompt &&
      selectedCompanyDetails.textPrompt &&
      !isPromptEditing ? (
        <>
          <div className="flex content-center px-10 mt-2 w-10/12 mx-auto">
            <Button
              className="w-1/2 mx-auto rounded-lg bg-red-100 p-2 shadow-md cursor-pointer hover:bg-red-200 "
              onClick={editPromptState}
            >
              Prompt for images
            </Button>

            <Button
              className="w-1/2 mx-auto rounded-lg bg-orange-100 p-2 shadow-md cursor-pointer hover:bg-orange-200"
              onClick={editPromptState}
            >
              Prompt for text
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className="flex w-3/4 center mx-auto  h-full mt-5">
            <ClickAwayListener onClickAway={promptStateClickAway}>
              <div className="w-full">
                <div className="flex flex-row w-full">
                  <Textarea
                    className="w-1/2 text-center mx-auto"
                    id="outlined-multiline-static2"
                    label="Image Prompt"
                    multiline
                    value={selectedCompanyDetails.imagePrompt}
                    onChange={handleImagePromptChange}
                    rows={7}
                  />

                  <Textarea
                    className="w-1/2 text-center mx-auto"
                    id="outlined-multiline-static3"
                    label="Text Prompt"
                    multiline
                    value={selectedCompanyDetails.textPrompt}
                    onChange={handleTextPromptChange}
                    rows={7}
                  />
                </div>
                <div>
                  <Button
                    variant="outlined"
                    type="button"
                    className="mt-auto text-black bg-custom-red hover:bg-indigo-700 normal-case"
                    onClick={handlePromptSubmit}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </ClickAwayListener>
          </div>
        </>
      )}
    </>
  );
};

export default PromptsAI;
