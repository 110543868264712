import React from "react";
import { Container, Typography } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <div>
      <Container maxWidth="sm" className="py-12 mb-4 text-justify">
        <Typography variant="h4" className="font-semibold mb-4" gutterBottom>
          Privacy Policy for Media Pilot
        </Typography>
        <Typography variant="body1" className="mb-4">
          Welcome to Media Pilot ("we," "us," or "our"). This Privacy Policy
          outlines how we collect, use, disclose, and protect your personal
          information when you use our services. By using our services, you
          agree to the terms of this Privacy Policy.
        </Typography>
        <Typography variant="body1" className="mb-4">
          We collect personal information when you use our service, including
          but not limited to: Information you provide during account
          registration, such as your name, email address, and profile
          information. Information generated by your use of our AI-powered image
          and video generation service. Information about your usage patterns,
          interactions with our service, and preferences.
        </Typography>
        <Typography variant="body1" className="mb-4">
          We use your personal information for various purposes, including:
          Providing and improving our services, such as generating images and
          videos based on AI prompts. Communicating with you about updates,
          promotions, and relevant information. Analyzing usage patterns to
          enhance the user experience and optimize our service.
        </Typography>
        <Typography variant="body1" className="mb-4">
          We do not claim ownership over the content generated using our
          service. We are not liable for any content generated or posted on user
          accounts. We may share your information under the following
          circumstances: With your explicit consent. For legal compliance,
          including responding to legal requests and protecting our rights. With
          service providers that help us deliver our services.
        </Typography>
        <Typography variant="body1" className="mb-4">
          We may request access to post content related to our service on your
          social media accounts. This access is limited to content directly
          related to our service and is subject to your authorization.
        </Typography>
        <Typography variant="body1" className="mb-4">
          We take reasonable measures to protect your personal information.
          However, no data transmission over the internet or storage system is
          completely secure. We cannot guarantee the security of your
          information.
        </Typography>
        <Typography variant="body1" className="mb-4">
          You have certain rights regarding your personal information, including
          the right to access, correct, or delete it. You can manage your
          account settings and privacy preferences within our service.
        </Typography>
        <Typography variant="body1" className="mb-4">
          Our service is not intended for children under the age of 18. We do
          not knowingly collect personal information from children under 18. If
          you are a parent or guardian and believe your child has provided us
          with personal information, please contact us to have the information
          removed.
        </Typography>
        <Typography variant="body1" className="mb-4">
          We may update this Privacy Policy periodically to reflect changes in
          our practices and service offerings. We will notify you of significant
          changes through our service or via email.
        </Typography>
        <Typography variant="body1" className="mb-4">
          If you have any questions, concerns, or requests regarding this
          Privacy Policy, please contact us at mediapilotofficial@gmail.com.
        </Typography>
        <Typography variant="body1" className="mb-4">
          This policy is effective as of August 9, 2023.
        </Typography>
      </Container>
    </div>
  );
};

export default PrivacyPolicy;
