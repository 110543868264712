import React, { useState } from "react";
import { TextField, Button, Container } from "@mui/material";

const ContactUs = (props) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your logic to handle form submission here
    // console.log(formData);
  };

  return (
    <div>
      <Container maxWidth="sm" className="py-12">
        <h1 className="py-5">Contact Our Team</h1>
        <form onSubmit={handleSubmit} className="space-y-6">
          <TextField
            name="name"
            label="Name"
            fullWidth
            variant="outlined"
            onChange={handleChange}
            value={formData.name}
          />
          <TextField
            name="email"
            label="Email"
            fullWidth
            variant="outlined"
            onChange={handleChange}
            value={formData.email}
          />
          <TextField
            name="message"
            label="Message"
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            onChange={handleChange}
            value={formData.message}
          />
          <Button
            type="submit"
            className="ml-4 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200"
            variant="contained"
          >
            Send Message
          </Button>
        </form>
      </Container>
    </div>
  );
};

export default ContactUs;
