import React, { useContext } from "react";
import { Link } from "@nextui-org/react";

import UserTimeCalculator from "../../components/homepage/UserTimeCalculator";
import ThreeTiles from "../../components/homepage/ThreeTiles";
import { AuthContext } from "../../App";

const HomePage = (props) => {
  const { loggedIn } = useContext(AuthContext);

  return (
    <div>
      {loggedIn === false ? (
        <div className="flex center flex-col font-lato">
          <div className="text-center text-4xl mt-5">We Craft, You Thrive.</div>
          <div className="text-center text-3xl">
            Tailored
            <span className="text-custom-red"> Social Media Content</span> for
            your Business.
          </div>
          <Link
            className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-custom-red hover:bg-indigo-700 my-8"
            variant="outlined"
          >
            Register Now
          </Link>
          <UserTimeCalculator />
          <ThreeTiles />
        </div>
      ) : (
        <div className="flex flex-col items-center">loading</div>
      )}
    </div>
  );
};

export default HomePage;
