import React, { useState } from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

const stylesData = {
  color: "#fc6262",
  height: 8,
  "& .MuiSlider-track": {
    border: "2px solid #ff0a0a",
    height: 16,
  },
  "& .MuiSlider-rail": {
    border: "2px solid #ff0a0a",
    boxShadow: "0px 1px 3px 8px rgba(200, 200, 200, 0.9)",
    height: 16,
  },
  "& .MuiSlider-thumb": {
    height: 36,
    width: 36,
    backgroundColor: "white",
    border: "5px solid #ff0a0a",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 60,
    height: 32,
    color: "black",
    borderRadius: "10px",
    backgroundColor: "#fc6262",
  },
};

const UserTimeCalculator = () => {
  const [sliderValues, setSliderValues] = useState([80, 33]);

  const handleSliderChange = (event, newValue, index) => {
    const newSliderValues = [...sliderValues];
    newSliderValues[index] = newValue;
    setSliderValues(newSliderValues);
  };

  const formattedProduct = (sliderValues[0] * sliderValues[1]).toLocaleString();
  const savings = Math.floor(
    parseFloat(formattedProduct.replace(/,/g, "")) * 0.25
  ).toLocaleString();

  return (
    <Box className="flex flex-col items-center w-3/4 mx-auto pt-8">
      <Typography
        className="text-lg mb-8 text-center"
        gutterBottom
        component="div"
      >
        How many hours do you spend a month creating content for social media?
      </Typography>
      <Slider
        sx={stylesData}
        aria-label="Always visible"
        defaultValue={80}
        step={1}
        min={30}
        max={300}
        valueLabelDisplay="on"
        onChange={(event, newValue) => handleSliderChange(event, newValue, 0)}
        valueLabelFormat={(value) => `${value} hrs`}
      />
      <Typography
        className="text-lg mb-8 mt-8 text-center"
        gutterBottom
        component="div"
      >
        How much is your time worth?
      </Typography>
      <Slider
        sx={stylesData}
        aria-label="Always visible"
        defaultValue={33}
        step={1}
        min={10}
        max={100}
        valueLabelDisplay="on"
        onChange={(event, newValue) => handleSliderChange(event, newValue, 1)}
        valueLabelFormat={(value) => `$${value}/hr`}
      />
      <div className="flex flex-col mt-8 w-full text-center">
        <div className="text-xl font-bold mb-4">
          <span className="">TIME COST</span>
        </div>
        <div className="text-3xl px-6 py-3 mb-4 rounded-lg shadow-md bg-red-200">
          $ {formattedProduct}
        </div>
        <div className="text-xl font-bold mb-4">
          <span className="">SAVINGS</span>
        </div>
        <div className="text-3xl px-6 py-3 mb-4 rounded-lg shadow-md bg-green-200">
          $ {savings} (25%)
        </div>
      </div>
    </Box>
  );
};

export default UserTimeCalculator;
