import { AppSyncApi } from "./AppSyncApi";

export class CompanyService {
  constructor(token) {
    this.api = new AppSyncApi(token);
  }

  async getAll() {
    const query = `query MyQuery {
        getAllCompanies {
          companyID
          companyName
          ownerID
          address
          description
          industry
          ownerEmail
        }
      }`;

    const response = await this.api.execute(query);
    return response;
  }

  async getAllImagesForCompany(companyID, year, month) {
    const query = `query MyQuery {
        getAllImages(imageDateSettings: { month: ${month}, year: ${year}}, companyID: "${companyID}"){
            imageUrls
            expirationDate
        }
      }`;

    const response = await this.api.execute(query);

    return response;
  }

  async createDashboard(formState, userInfo, companiesGQLInput) {
    const query = `mutation MyMutation {
        addCompany(
          companySettings: {
            companyID: "${formState.companyID}"
            companyName: "${formState.companyName}"
            address: "${formState.address}"
            description: "${formState.description}"
            industry: "${formState.industry}"
            textPrompt: "Craft a concise, engaging, and funny description for my social media post. The description should include things related to the ${formState.industry} industry. Keep the description less than 200 characters."
            imagePrompt: "Produce a clean, square image, highlighting recognizable items related to ${formState.industry}, set against a vibrant, solid-colored background. The image should be eye-catching, suitable for engaging social media use. It should be one singular item, instead of multiple items."
          }
        ) {
          companyID
          companyName
          address
          description
          industry
        }


        updateUser(userSettings: {companies: [${companiesGQLInput}]}) {
          userID
          email
          }
        }
        `;

    const response = await this.api.execute(query);
    console.log(response);
    return response;
  }

  async createNewImage(companyID, companyImagePrompt, year, day, month) {
    const query = `mutation MyMutation {
        createImages(imageDateSettings: { day: ${day}, month: ${month}, year: ${year} }, companyID:"${companyID}",companyImagePrompt:"${companyImagePrompt}")}`;
    console.log(query);
    const response = await this.api.execute(query);
    console.log(response);
    return response;
  }

  async getCompanyByID(companyID) {
    const query = `query MyQuery {
      getCompanyByID(companyID: "${companyID}") {
        imagePrompt
        textPrompt
      }
    }`;
    const response = await this.api.execute(query);

    return response;
  }

  async getCompanyPosts(companyID, postMonth) {
    const query = `query MyQuery {
      getCompanyPosts(companyID:"${companyID}", postMonth:${postMonth}) {
        companyID
        postID
        postMonth
        postYear
        postDay
        postContent
      }
    }`;
    const response = await this.api.execute(query);

    return response;
  }

  async createText(companyID, prompt, postDay, postMonth, postYear) {
    const query = `mutation MyMutation {
      createText(companyID: "${companyID}", 
      prompt: "${prompt}", 
      postDay:${postDay}, 
      postMonth: ${postMonth}, 
      postYear:${postYear})
    }
    `;
    const response = await this.api.execute(query);
    // console.log(response);
    return response;
  }

  async updateCompanyPost(companyID, postID, content) {
    const query = `mutation MyMutation {
      updateCompanyPost(companyID: "${companyID}", postID: "${postID}", postContent: "${content}") {
        companyID
        createdAtUnix
        postContent
        postDay
        postID
        postMonth
        postYear
        updatedAtUnix
      }
    }`;
    console.log(query);
    const response = await this.api.execute(query);
    console.log(response);
    return response;
  }

  async updateCompanyDetails(
    companyID,
    companyName,
    description,
    address,
    industry,
    textPrompt,
    imagePrompt
  ) {
    const query = `mutation MyMutation {
      updateCompany(
        companySettings: {
          companyID: "${companyID}"
          companyName: "${companyName}"
          description: "${description}"
          address: "${address}"
          industry: "${industry}"
          textPrompt: "${textPrompt}"
          imagePrompt: "${imagePrompt}"
        }
      ) {
          companyID
          companyName
          ownerID
          description
          address
          industry
          textPrompt
          imagePrompt
      }
    }`;
    const response = await this.api.execute(query);
    console.log(response);
    return response;
  }

  async putImages(companyID, postDay, postMonth, postYear) {
    const query = `query MyQuery {
      putImages(imageDateSettings: { month: ${postMonth}, year: ${postYear}, day:${postDay} } companyID: "${companyID}"){
          imageUrls
          expirationDate
      }
    }`;

    const response = await this.api.execute(query);
    console.log(response);
    return response;
  }
}
