import React, { useContext, useEffect, useState } from "react";

import { IconButton, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { v4 as uuidv4 } from "uuid";

import PostDescriptionEditor from "./PostDescriptionEditor";
import emptyImage from "../../images/MediaPilotLogo.png";
import { getDay } from "../constants";
import { AuthContext } from "../../App";

import { Card, CardBody } from "@nextui-org/react";

const CardTile = (props) => {
  const { services } = useContext(AuthContext);
  const [isEditing, setIsEditing] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [postValue, setPostValue] = useState(
    props.companyPosts || "Needs Description"
  );

  useEffect(() => {
    console.log("useEffect");
    setPostValue(props.companyPosts || "Needs Description");
  }, [props.companyPosts]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    setIsEditing(false);
  };

  const handlePostTextChange = (e) => {
    setPostValue(e.target.value);
  };

  const handleUpload = async () => {
    console.log("handleUpload");
    // console.log(props.companyID);
    let companyPostID = "";
    if (props.companyPostID === undefined || props.companyPostID === null) {
      companyPostID = uuidv4();
    } else {
      companyPostID = props.companyPostID;
    }
    // console.log(postValue);
    await services.companyService.updateCompanyPost(
      props.companyID,
      companyPostID,
      postValue
    );
  };

  const handleNewImage = async (
    currentDay,
    companyImagePrompt,
    currentYear,
    companyID,
    currentMonthNumber
  ) => {
    await services.companyService.createNewImage(
      companyID,
      companyImagePrompt,
      currentYear,
      currentDay,
      currentMonthNumber
    );
  };

  const handleNewText = async (
    currentDay,
    companyID,
    companyTextPrompt,
    currentYear,
    currentMonthNumber
  ) => {
    console.log("create new AI text");
    // console.log(companyTextPrompt);
    await services.companyService.createText(
      companyID,
      companyTextPrompt,
      currentDay,
      currentMonthNumber,
      currentYear
    );
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    if (
      file &&
      file.type === "image/jpeg" && // Changed to check for "image/jpeg"
      file.size <= 5 * 1024 * 1024
    ) {
      console.log("File is acceptable.");
      await uploadImage(file);

      // Continue with upload or other processes
    } else {
      console.warn("File must be a jpg image and less than 5MB.");
      // Inform user of invalid file
    }
  };

  const uploadImage = async (file) => {
    console.log("uploadImage");
    console.log(
      props.companyID,
      props.currentDay,
      props.currentMonthNumber,
      props.currentYear
    );
    try {
      let response = await services.companyService.putImages(
        props.companyID,
        props.currentDay,
        props.currentMonthNumber,
        props.currentYear
      );
      const presignedUrl = response.data.data.putImages.imageUrls[0];

      const uploadResponse = await fetch(presignedUrl, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": "image/jpeg",
        },
      });
      if (uploadResponse.ok) {
        console.log("File successfully uploaded");
      } else {
        console.error(
          "File upload failed:",
          uploadResponse.status,
          uploadResponse.statusText
        );
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  return (
    <Card
      className={`flex ${
        props.shouldDoubleWidth ? "w-2/6" : "w-1/6"
      } mr-10 mb-7 center border-red-400 rounded-xl shadow-xl transform hover:scale-105 transition-transform duration-500 `}
    >
      <div className=" relative">
        <div className="flex text-red-600 mb-3 justify-center items-center">
          {props.currentMonth} {props.currentDay} - {""}
          {getDay(props.currentMonth, props.currentDay, props.currentYear)}
        </div>
        <div
          className="relative"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {
            <div className="relative">
              <img
                src={props.imageUrl ? props.imageUrl : emptyImage}
                alt="Logo"
                className="self-center"
              />
              {isHovered && (
                <div className="absolute top-0 right-0 p-2 flex flex-col items-end space-y-2 rounded">
                  <Tooltip title="Create New Image" placement="top">
                    <IconButton
                      value={props.currentDay}
                      style={{ color: "black" }}
                      onClick={(event) =>
                        handleNewImage(
                          props.currentDay,
                          props.companyImagePrompt,
                          props.currentYear,
                          props.companyID,
                          props.currentMonthNumber
                        )
                      }
                    >
                      <AddPhotoAlternateIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Create New Text" placement="top">
                    <IconButton
                      value={props.currentDay}
                      style={{ color: "black" }}
                      onClick={(event) =>
                        handleNewText(
                          props.currentDay,
                          props.companyID,
                          props.companyTextPrompt,
                          props.currentYear,
                          props.currentMonthNumber
                        )
                      }
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Upload New Image" placement="top">
                    <label htmlFor="fileInput">
                      <div>
                        <IconButton
                          style={{ color: "black" }}
                          onClick={() =>
                            document.getElementById("fileInput").click()
                          }
                        >
                          <CloudUploadIcon />
                        </IconButton>
                        <input
                          id="fileInput"
                          type="file"
                          hidden
                          onChange={handleImageChange}
                          accept="image/jpeg" // Changed to only accept jpg files
                        />
                      </div>
                    </label>
                  </Tooltip>
                </div>
              )}
            </div>
          }
        </div>

        <CardBody className="flex flex-row items-center">
          <PostDescriptionEditor
            isEditing={isEditing}
            companyPosts={props.companyPosts}
            postValue={postValue}
            handleEdit={handleEdit}
            handleBlur={handleBlur}
            handlePostTextChange={handlePostTextChange}
            handleUpload={handleUpload}
          />
        </CardBody>
      </div>
    </Card>
  );
};

export default CardTile;
