import React from "react";
import Button from "@mui/material/Button";

const NotFound = (props) => {
  return (
    <div>
      <Button variant="outlined">NOT FOUND</Button>
    </div>
  );
};

export default NotFound;
