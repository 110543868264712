import axios from "axios";
import environmentVariables from "../envVariables";

export class AppSyncApi {
  #endPoint = environmentVariables["APPSYNC_GRAPHQL_ENDPOINT"];
  #token = "";

  constructor(token) {
    this.#token = token;
  }

  async execute(query) {
    const response = await axios({
      url: this.#endPoint,
      method: "POST",
      headers: {
        Authorization: `Bearer ${this.#token}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        query: query,
      }),
    });

    return response;
  }
}
