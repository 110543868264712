import React from "react";
import ClickAwayListener from "@mui/base/ClickAwayListener";

import { Textarea, Button } from "@nextui-org/react";

const PostDescriptionEditor = ({
  isEditing,
  companyPosts,
  postValue,
  handleEdit,
  handleBlur,
  handlePostTextChange,
  handleUpload,
}) => {
  return !isEditing ? (
    companyPosts ? (
      <p
        className="flex h-[150px] w-full no-scrollbar overflow-auto border-red-500 break-all"
        onClick={handleEdit}
      >
        {postValue}
      </p>
    ) : (
      <div className="flex center text-red-600 h-[150px] w-full">
        <Textarea
          className="flex w-full"
          id="outlined-multiline-static"
          label="Post Description"
          multiline
          rows={4}
          defaultValue="Needs Description"
          onClick={handleEdit}
        />
      </div>
    )
  ) : (
    <ClickAwayListener onClickAway={handleBlur}>
      <div className="flex flex-col w-full">
        <Textarea
          className="flex w-full"
          id="outlined-multiline-static"
          label="Post Description"
          multiline
          rows={6}
          value={postValue}
          onChange={handlePostTextChange}
        />
        <Button
          variant="outlined"
          className="text-white bg-custom-red hover:bg-indigo-700 normal-case"
          onClick={handleUpload}
        >
          Save
        </Button>
      </div>
    </ClickAwayListener>
  );
};

export default PostDescriptionEditor;
