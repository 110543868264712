import { AppSyncApi } from "./AppSyncApi";

export class StripeService {
  constructor(token) {
    this.api = new AppSyncApi(token);
  }

  async getStripeCheckoutLink(customerEmail, planName, planQuantity) {
    const query = `query MyQuery {
        getStripeCheckoutLink(
         customerEmail: "${customerEmail}"
         planName: "${planName}"
         planQuantity: ${planQuantity}
       )
    }`;

    const response = await this.api.execute(query);

    return response;
  }
}
