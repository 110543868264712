import { AppSyncApi } from "./AppSyncApi";

export class UserService {
  constructor(token) {
    this.api = new AppSyncApi(token);
  }

  async getUserByID(cognitoUsername) {
    const query = `query MyQuery {
        getUserByID(userID:"${cognitoUsername}") {
          companies{
            companyID
            companyName
          }
          currentTokens
          tokenCount
          planName
          subscriptionEndUnix
        }
      }`;

    const response = this.api.execute(query);

    return response;
  }
}
