export const getIdToken = () => localStorage.getItem("id_token");
export const getAccessToken = () => localStorage.getItem("access_token");
export const isAuthenticated = () => getAccessToken() && getIdToken();

export const isTokenExpired = (exp) => {
  const currentUnixTimestamp = Math.floor(Date.now() / 1000);
  return currentUnixTimestamp >= exp;
};

export const setToken = (access_token, id_token) => {
  localStorage.setItem("access_token", access_token);
  localStorage.setItem("id_token", id_token);
};

export const removeAccessToken = () => localStorage.removeItem("access_token");
export const removeIdToken = () => localStorage.removeItem("id_token");

export const removeTokens = () => {
  removeAccessToken();
  removeIdToken();
};

export function gqlInputFromArrayOfObjects(arr) {
  return arr
    .map(
      (obj) =>
        `{${Object.entries(obj)
          .map(([k, v]) => `${k}: "${v}"`)
          .join(", ")}}`
    )
    .join(", ");
}
