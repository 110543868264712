import React, { useContext } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  Link,
  User,
} from "@nextui-org/react";

import { AuthContext } from "../../App";

const LoginMenu = (props) => {
  const { loggedIn, userInfo } = useContext(AuthContext);

  if (!loggedIn) {
    return (
      <Link isBlock href={props.GOOGLE_AUTH_ENDPOINT} className="p-2 px-3">
        Login
      </Link>
    );
  }

  return (
    <Dropdown placement="bottom" unselectable="on">
      <DropdownTrigger>
        <User
          as="button"
          avatarProps={{
            isBordered: true,
            src: userInfo.profilePicture,
          }}
          className="transition-transform"
          description={userInfo.emailAddress}
          name={userInfo.nickname}
        />
      </DropdownTrigger>
      <DropdownMenu aria-label="Profile Actions" variant="flat">
        <DropdownSection showDivider>
          <DropdownItem key="terms">
            <Link href="/terms-and-conditions" color="foreground">
              Terms and Conditions
            </Link>
          </DropdownItem>
          <DropdownItem key="privacy-policy">
            <Link href="/privacy-policy" color="foreground">
              Privacy Policy
            </Link>
          </DropdownItem>
          <DropdownItem key="contact">
            <Link href="/contact-us" color="foreground">
              Contact Us
            </Link>
          </DropdownItem>
        </DropdownSection>
        <DropdownItem key="logout" color="danger" onClick={logOut}>
          Log Out
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default LoginMenu;

const logOut = () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("id_token");
  window.location.href = "/";
};
