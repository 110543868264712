import React, { useContext, useState } from "react";
import { Button, TextField } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { AuthContext } from "../../App";

const AdminOnboardCompaniesPage = (props) => {
  const { userInfo, services } = useContext(AuthContext);
  const [formState, setFormState] = useState({
    companyName: "",
    address: "",
    description: "",
    industry: "",
    ownerID: userInfo.cognitoUsername,
    companyID: uuidv4(),
    emailAddress: userInfo.emailAddress,
  });

  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = () => {
    console.log("submitted");
    console.log(formState);

    services.companyService.createDashboard(formState, userInfo);
  };
  return (
    <div className="flex flex-col items-center">
      <div className="flex mb-10 mt-10">Onboard Companies Page</div>
      <div className="flex flex-col w-3/4">
        <TextField
          id="companyName"
          label="Company Name"
          variant="outlined"
          className="mb-10"
          value={formState.companyName}
          onChange={handleChange}
          required
        />

        <TextField
          id="address"
          label="Address"
          variant="outlined"
          className="mb-10"
          value={formState.address}
          onChange={handleChange}
        />

        <TextField
          id="description"
          label="Description"
          variant="outlined"
          className="mb-10"
          value={formState.description}
          onChange={handleChange}
        />

        <TextField
          id="industry"
          label="Industry"
          variant="outlined"
          className="mb-10"
          value={formState.industry}
          onChange={handleChange}
        />

        <Button variant="outlined" className="mb-10" onClick={handleSubmit}>
          Submit
        </Button>

        <Button variant="outlined" href="/admins" className="mb-10">
          Back
        </Button>
      </div>
    </div>
  );
};

export default AdminOnboardCompaniesPage;
