import { useContext, useEffect, useState, useCallback } from "react"; // Import useCallback
import { AuthContext } from "../App";

export const useCompanies = () => {
  const { loggedIn, userInfo, services } = useContext(AuthContext);
  const [companies, setCompanies] = useState([]);

  const fetchCompanies = useCallback(async () => {
    // Use the useCallback hook
    if (loggedIn) {
      const companiesResponse = await services.companyService.getAll();
      setCompanies(companiesResponse.data.data.getAllCompanies);
    }
  }, [loggedIn, services.companyService]); // Include dependencies here

  useEffect(() => {
    console.log("useEffect");
    fetchCompanies();
  }, [userInfo.idToken, services.companyService, fetchCompanies]); // Add fetchCompanies to the dependency array

  return companies;
};
