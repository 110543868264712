import { createContext, useState, useEffect, useCallback } from "react";
import { Route, Routes } from "react-router-dom";
import queryString from "query-string";
import jwtDecode from "jwt-decode";
import { NextUIProvider } from "@nextui-org/react";

import HomePage from "./pages/Main/HomePage";
import DashboardPage from "./pages/Main/MainDashboard";
import ListOfCompaniesPage from "./pages/Admin/ListOfCompaniesPage";
import AdminOnboardCompaniesPage from "./pages/Admin/OnboardCompaniesPage";
import ContactUs from "./pages/UserInput/ContactUs";
import PrivacyPolicy from "./pages/Information/PrivacyPolicy";
import TermsAndConditions from "./pages/Information/TermsAndConditions";
import NotFound from "./pages/Information/NotFound";

import { UserService } from "./utils/UserService";
import { CompanyService } from "./utils/CompanyService";
import { StripeService } from "./utils/StripeService";
import {
  getIdToken,
  isAuthenticated,
  isTokenExpired,
  setToken,
  removeTokens,
} from "./utils/reusableFunctions";

import NavbarMenu from "./components/navbar/NavbarMenu";

import environmentVariables from "./envVariables";

import "./App.css";
import "./style.css";
import SubscriptionCards from "./pages/Information/SubscriptionCards";

export const AuthContext = createContext();

function App() {
  const GOOGLE_AUTH_ENDPOINT = `${environmentVariables["COGNITO_AUTH_ENDPOINT"]}/oauth2/authorize?identity_provider=Google&client_id=${environmentVariables["COGNITO_CLIENT_ID"]}&response_type=TOKEN&scope=email+openid+profile&redirect_uri=${environmentVariables["CLOUDFRONT_DOMAIN_URL"]}`;
  const [loggedIn, setLoggedIn] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [services, setServices] = useState({});

  const fetchCompanies = async (idTokenCredential, cognitoUsername) => {
    let isSubscriptionExpired;
    const userService = new UserService(idTokenCredential);
    const response = await userService.getUserByID(cognitoUsername);
    const companiesData = response.data.data.getUserByID.companies || [];

    const subscriptionEndUnit =
      response.data.data.getUserByID.subscriptionEndUnix;

    isSubscriptionExpired =
      subscriptionEndUnit === null || subscriptionEndUnit === undefined
        ? true
        : isTokenExpired(subscriptionEndUnit);

    setUserInfo((prevUserInfo) => ({
      ...prevUserInfo,
      companies: companiesData,
      isSubscriptionExpired: isSubscriptionExpired,
      currentTokens: response.data.data.getUserByID.currentTokens,
      tokenCount: response.data.data.getUserByID.tokenCount,
      planName: response.data.data.getUserByID.planName,
      subscriptionEndUnix: response.data.data.getUserByID.subscriptionEndUnix,
    }));
  };

  const handleLogin = useCallback((idToken) => {
    const decodedIdToken = jwtDecode(idToken);

    setUserInfo({
      emailAddress: decodedIdToken.email || null,
      emailVerified: decodedIdToken.email_verified || null,
      nickname: decodedIdToken.nickname || null,
      profilePicture: decodedIdToken.picture || null,
      givenName: decodedIdToken.given_name || null,
      cognitoGroups: decodedIdToken["cognito:groups"] || null,
      idToken: idToken,
      cognitoUsername: decodedIdToken["cognito:username"] || null,
    });

    if (!isTokenExpired(decodedIdToken.exp)) {
      // console.log("token not expired");
      setLoggedIn(true);
      fetchCompanies(idToken, decodedIdToken["cognito:username"]);
      setServices({
        companyService: new CompanyService(idToken),
        userService: new UserService(idToken),
        stripeService: new StripeService(idToken),
      });
    } else {
      // console.log("token expired");
      setLoggedIn(false);
      removeTokens();
      setServices({});
    }
  }, []);

  useEffect(() => {
    const parsedUrl = queryString.parse(window.location.hash);
    const { access_token, id_token } = parsedUrl;
    if (isAuthenticated()) {
      // console.log("isAuthenticated");
      handleLogin(getIdToken());
    } else if (access_token && id_token) {
      // console.log("access_token and id_token in url");
      setToken(access_token, id_token);
      handleLogin(id_token);
    } else {
      // console.log("not authenticated");
      setLoggedIn(false);
      removeTokens();
    }

    window.history.replaceState(null, null, window.location.pathname);
    // eslint-disable-next-line
  }, []);

  return (
    <AuthContext.Provider
      value={{
        loggedIn: loggedIn,
        userInfo: userInfo,
        userCognitoGroups: userInfo.cognitoGroups,
        services: services,
      }}
    >
      <NextUIProvider>
        <div className="App">
          <NavbarMenu GOOGLE_AUTH_ENDPOINT={GOOGLE_AUTH_ENDPOINT} />
          <Routes>
            {loggedIn ? (
              <Route path="/" element={<DashboardPage />} />
            ) : (
              <Route
                path="/"
                element={
                  <HomePage GOOGLE_AUTH_ENDPOINT={GOOGLE_AUTH_ENDPOINT} />
                }
              />
            )}
            {loggedIn &&
              userInfo.cognitoGroups &&
              userInfo.cognitoGroups.includes("Admins") && (
                <>
                  <Route
                    path="/list-of-companies"
                    element={<ListOfCompaniesPage />}
                  />
                  <Route
                    path="/admin-onboard-companies"
                    element={<AdminOnboardCompaniesPage />}
                  />
                </>
              )}

            <Route path="/pricing" element={<SubscriptionCards />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </NextUIProvider>
    </AuthContext.Provider>
  );
}

export default App;
