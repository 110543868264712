export class DateFunctions {
  currentDate = new Date();

  currentMonth = this.currentDate.toLocaleString("default", {
    month: "long",
  });

  currentMonthNumber = this.currentDate.getMonth() + 1;

  numberOfDaysInMonth = new Date(
    this.currentDate.getFullYear(),
    this.currentDate.getMonth() + 1,
    0
  ).getDate();

  currentYear = this.currentDate.getFullYear();
}
