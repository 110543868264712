import React, { useContext } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Link,
  Navbar,
  NavbarContent,
  NavbarItem,
  Tooltip,
  Button,
} from "@nextui-org/react";
import { useLocation } from "react-router-dom";

import logo from "../../images/MediaPilotLogo.png";
import { AuthContext } from "../../App";
import LoginMenu from "./LoginMenu";

const BasicMenu = [
  {
    title: "Privacy Policy",
    href: "/privacy-policy",
  },
  {
    title: "Terms & Conditions",
    href: "/terms-and-conditions",
  },
];

const UserMenu = [
  {
    title: "Dashboard",
    href: "/",
  },
];

const AdminMenu = [
  {
    title: "Companies",
    href: "/list-of-companies",
  },
  {
    title: "Onboard Company",
    href: "/admin-onboard-companies",
  },
  {
    title: "Metrics",
    href: "/metrics",
  },
];

const NavbarMenu = (props) => {
  const { loggedIn, userInfo } = useContext(AuthContext);
  console.log(userInfo);
  const location = useLocation();

  // TODO should be in AuthContext
  const isAdmin =
    loggedIn &&
    userInfo.cognitoGroups &&
    userInfo.cognitoGroups.includes("Admins");

  return (
    <Navbar
      isBordered
      isBlurred
      maxWidth="2xl"
      className="select-none  border-b"
    >
      <NavbarContent justify="start">
        <a href="/" className="w-16">
          <img
            className="items-center h-12 object-scale-down"
            src={logo}
            alt="Logo"
          />
        </a>

        {loggedIn &&
          UserMenu.map((item, index) => {
            const active = location.pathname === item.href;
            return (
              <NavbarItem key={index} isActive={active}>
                <Link
                  isBlock
                  href={item.href}
                  color={active ? "primary" : "foreground"}
                  className="p-2 px-3"
                >
                  {item.title}
                </Link>
              </NavbarItem>
            );
          })}

        {isAdmin ? (
          <NavbarItem>
            <Dropdown placement="bottom" unselectable="on">
              <DropdownTrigger>
                <Link isBlock color={"foreground"} className="p-2 px-3">
                  Admin
                </Link>
              </DropdownTrigger>
              <DropdownMenu aria-label="Profile Actions" variant="flat">
                {AdminMenu.map((item, index) => {
                  return (
                    <DropdownItem key={index}>
                      <Link href={item.href} color={"foreground"}>
                        {item.title}
                      </Link>
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </Dropdown>
          </NavbarItem>
        ) : null}
      </NavbarContent>

      <NavbarContent justify="end">
        {!loggedIn ? (
          <NavbarItem>
            <Link
              isBlock
              href={"/pricing"}
              color={
                location.pathname === "/pricing" ? "primary" : "foreground"
              }
              className="p-2 px-3"
            >
              Pricing
            </Link>
            <Dropdown placement="bottom" unselectable="on">
              <DropdownTrigger>
                <Link isBlock color={"foreground"} className="p-2 px-3">
                  Company
                </Link>
              </DropdownTrigger>
              <DropdownMenu aria-label="Navigation Actions" variant="flat">
                {BasicMenu.map((item, index) => {
                  const active = location.pathname === item.href;
                  return (
                    <DropdownItem key={index}>
                      <Link
                        href={item.href}
                        color={active ? "primary" : "foreground"}
                      >
                        {item.title}
                      </Link>
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </Dropdown>
          </NavbarItem>
        ) : loggedIn && !userInfo.isSubscriptionExpired ? (
          <>
            <Tooltip
              content={`${userInfo.currentTokens}/${userInfo.tokenCount} Remaining Tokens`}
            >
              <Button>Tokens</Button>
            </Tooltip>
          </>
        ) : null}

        <LoginMenu GOOGLE_AUTH_ENDPOINT={props.GOOGLE_AUTH_ENDPOINT} />
      </NavbarContent>
    </Navbar>
  );
};

export default NavbarMenu;
